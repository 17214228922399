@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.dashboard-container{
    background: url('./../../assets/images/wave.svg');
    min-height: 100vh;
    background-repeat: no-repeat;
    overflow-y: hidden;
}
.dashboard-container-rtl{
    background: url('./../../assets/images/wave-op.svg');
    height: 100vh;
    background-repeat: no-repeat;
    overflow-y: hidden;
    direction: rtl;
    background-position: right;

}
@media only screen and (max-width:768px){
    .dashboard-container{
        background: $gray-color-2;
    }
    .dashboard-container-rtl{
        background: $gray-color-2;
    }
}