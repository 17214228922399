@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.player-details-container{
    display: flex;
    justify-content: space-evenly;
    .col-one{
        width: 25%
    }
    .col-two{
        width: 68%
    }
    
    .team-logo-container{
        position: absolute;
        display: flex;
        justify-content: center;
        border-radius: 60px;
        background: white;
        height: 120px;
        width: 120px;
        align-items: center;
        bottom: -60px;
        &.right{
            right: 33%;
        }
        &.left{
            left: 33%;
        }
        .team-logo{
            width: 80px;
            height: 80px;
        }
    }
    .players-basic-details{
        background: $gray-color-2;
        height: 60vh;
        margin: 40px 10px 10px;
        border-radius: 10px;
        padding:30px 20px 20px;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
        position: relative;
        .player-number{
            font-size: 120px;
            position: fixed;
            bottom: 200px;
            top: 30px;
            left: 180px;
            background:  -webkit-linear-gradient(180deg, rgba(0,244,188,1) 35%, rgba(50,150,150,1) 100%);;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // font-style: italic
        }
        .player-name{
            @include font-bold;
            font-size: 40px;
            text-align: center;
            margin: 0;
            line-height: 1;
            margin: 12px;
        }
        .player-percentage{
            text-align: center;
            color: red;
        }
        .player-position{
            background: $green-gradient;
            padding: 10px;
            text-align: center;
            border-radius: 20px;  
            color: white;
            font-weight: bold;
            font-size: 18px;  
        }
        .player-detais{
            padding: 20px;
            font-size: 20px;
            .title{
                @include font-bold;
            }
            .value{
                margin-left: 10px
            }
        }
    }
    .players-stats{
        background: $gray-color-2;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
        height: 80vh;
        .player-stats-head{
            @include font-bold;
            padding: 10px;
            font-size: 24px;
        }
        .player-stats-content{
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            .row{
                display: flex;
                margin: 5px;
                .col-one{
                    width: 40%
                }
                .col-two{
                    width: 60%;
                    display: flex;
                    justify-content: space-evenly
                }
            }
            .box{
                width: 55px;
                height: 40px;
                /* padding: 10px; */
                background: white;
                display: flex;
                /* align-self: stretch; */
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                // margin-right: 10px;
            }
            .head{
                width: 45px;
                font-size: 12px;
            }
            .item{
                background: white;
                display: flex;
                align-items: center;
                padding-left: 10px;
                border-radius: 5px;
               
            }
        }
        .stats-cat{
            width: 45%;
            margin: 20px;
        }

    }
    .player-stats-content-head{
        display: flex;
        align-items: center;
        .icon{
            width: 25px;
            height:25px;
        }
        .title{
            margin-left: 10px;
            font-size: 20px
        }
    }
    .highlight{
        border:1px solid $light-green
    }
}
// .slick-slide
// {
//     width: 300px;
// }
.slick-track{
    display: flex !important
}
@media only screen and (max-width:768px){
    .player-details-container{
        justify-content: initial;
        flex-direction: column;
        height: 85vh;
        overflow-y: auto;
        -webkit-overflow-scrolling:touch;
        .players-basic-details-mobile{
            .player-name-mobile{
                @include font-bold;
                padding:0 20px;
                font-size: 1.5rem;
                margin-bottom: 0;
            }
            .player-number-container{
                height: 130px;
            }
            .team-logo-mobile{
                width: 40px;
                height: 40px;
                margin: 0 20px;
            }
            .player-position-mobile{
                @include font-bold;
                background: $green-gradient;
                width: 200px;
                padding: 8px;
                color: white;
                font-size: 18px;
                box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
            }
            .raduis{
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
            }
            .rtl-raduis{
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
            }
            .player-number{
                background:  -webkit-linear-gradient(180deg, rgba(0,244,188,1) 35%, rgba(50,150,150,1) 100%);;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                line-height: 100px;
                font-size: 120px;
                // font-style: italic
            }
            .player-basic-info{
                margin: 5px;
            }
            .player-position-container{
                display: flex;
                position: relative;
                top: 10px;
            }
            .player-basic-info-card-mobile{
                box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
                margin: 10px;
                display: flex;
                padding: 20px;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;
                margin-top: -20px;
                .title{
                    @include font-bold;
                }
                .value{
                    margin-left: 10px
                }
            }
        }
        .players-stats-mobile{
            .player-stats-head{
                margin: 0 20px;
            }
            .slider-card{
                background-image: linear-gradient(to top, #00f4bc, #00dcb8, #00c5b1, #1bada5, #329696);  
                width: 280px !important;
                margin: 10px;
                border-radius: 10px;
            }
            .item{
                font-size: 20px
            }
            .row{
                background: $gray-color-2;
                margin: 10px;
                border-radius: 10px;
                padding: 10px;
            }
            .item-row{
                display: flex;
                justify-content: space-between
            }
            .box{
                width: 55px;
                height: 40px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
            }
            .head{
                width: 55px;
                font-size: 14px;
                margin: 5px;
            }
          
        }
        .players-stats{
            margin: 10px
        }
        .col-one{
            width: 100%
        }
        .col-two{
            width: 100%
        }
        .player-stats-content-head{
            margin: 10px;
            .icon{
                width: 35px;
                height:35px;
            }
            .title-mobile{
                margin-left: 10px;
                font-size: 30px;
                color: white
            }
        }
    }
}