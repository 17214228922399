@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.team-container{
    display: flex;
}
.video-highlits{
    width: 40%
}
.team-details-container{
        display: flex;
        flex-direction: column;
        width: 60%;
        .team-heading{
            display: flex;
            align-items: center;
        }
        .team-logo{
            width: 40px;
            height: 35px;
            cursor: pointer;
        }
        .team-name{
            @include font-bold;
            margin:0 10px;
            font-size: 2.5rem;

        }
        .team-details-row{
            display: flex;
        }
        .row{
            display: flex;
        }
        .team-details-wrapper{
            background:$gray-color-2;
            border-radius: 10px;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
            padding: 10px;
            .league-heading{
                margin: 0 5px;
            }
        }
        .team-statistics-container{
            margin: 5px;
       
        }
        .team-details-item{
            display: flex;
            justify-content: space-between;
            margin: 5px;
        }
        .team-details-item-title{
            width: 70%;
            background: white;
            border-radius: 5px;
            padding: 6px;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
        }
        .team-details-item-value{
            width: 20%;
            background: white;
            padding: 0 10px;
            border-radius: 5px;
            margin: 0 5px;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
        }
        .col-one-width{
            width: 35%
        }
        .col-two-width{
            width: 65%
        }
        .players-transfer-container{
            margin: 5px;
            .players-transfer-item{
                display: flex;
                justify-content: space-between;
                background: white;
                border-radius: 5px;
                padding: 5px;
                box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
                margin: 5px;
            }
            .players-transfers{
                height: 170px;
                overflow-y: auto;
            }
            .player-details{
                display: flex;
                flex-direction: column;
            }
            .right-arrow{
                width:20px;
                height: 20px;
                padding: 4px;
            }
            .player-transfer-details{
                display: flex;
                align-items: center
            }
            .dob{
                font-size: 12px;
            }
        }
        .team-players-container{
            margin: 0 20px;
            a>span{
                color:black !important;
            }
            .team-players-stats{
                display: flex;
                flex-direction: column;
            }
            .dots-container{
                display: none
            }
            .players-details{
                height: 60vh;
                overflow-y: auto;
                -webkit-overflow-scrolling:touch;
            }
            .row{
                display: flex;
                margin-top: 5px;
            }
            .col-one{
                width: 56%
            }
            .col-two{
                width: 74%;
                display: flex;
                justify-content: space-between;
            }
            .head{
                width: 40px;
            }
            .player-name{
                background: white;
                display: flex;
                align-items: center;
                padding-left: 10px;
                border-radius: 5px;
               
            }
            .srNo{
                font-weight: bold
            }
            .box{
                width: 55px;
                height: 40px;
                /* padding: 10px; */
                background: white;
                display: flex;
                /* align-self: stretch; */
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                // margin-right: 10px;
            }
        }
        .empty-data{
            display: flex;
            justify-content: center;
            height: inherit;
            align-items: center;
        }
        
}
@media only screen and (max-width:768px){
    .team-container{
        flex-direction: column;
    }
    .team-details-container{
        width: 100%;
        height: 84vh;
        overflow-y: auto;
        -webkit-overflow-scrolling:touch;
        .team-heading{
            padding: 10px 20px;
        }
        .team-name{
            font-size: 1.5rem;

        }
        .team-logo {
            width: 40px;
            height: 35px;        
        }
        .team-details-row{
            flex-direction: column;
            align-items: center
        }
        .col-one-width{
            width: 95%
        }
        .col-two-width{
            width: 95%
        }
        .team-players-container{
            margin: 0;
            .player-name{
                font-size: 13px;
            }
            .dots-container{
                display: flex;
                .selected-dot{
                    background: $light-green;
                    width: 15px;
                    height: 15px;
                    border-radius: 10px;
                    margin: 5px;
                    cursor: pointer;
                }
                .not-selected-dot{
                    background: #ada8a8;
                    width: 15px;
                    height: 15px;
                    border-radius: 10px;
                    margin: 5px;
                    cursor: pointer;
                }
            }
        }

    }
}