@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .logo-conrainer{
        width: 30%;
        max-width: 180px;
        .logo-img{
            width: 100%
        }
    }
    .header-menu-container{
        display: flex;
        align-items: center;
        width: 100%;
    }

    .header-menu{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 60%;
    }
    .header-menu-width{
        width: 77%;
    }
    .menu-item{
        background:$gray-color;
        color: $black-color;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 20px;
        .menu-image{
            height: 20px;
            width: 20px;

         
        }
       svg path{
            fill:'#00F4BC' !important
        }
        .menu-text{
            @include font-regular;
            margin-left: 10px;
            padding-bottom: 5px;
            font-size: 13px;
        }
    }

    .active-menu-item{
        background:$black-color;
        color: $light-green;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 20px;
        .menu-image{
            height: 20px;
            width: 20px;
        }
        .menu-text{
            @include font-regular;
            margin-left: 10px;
            padding-bottom: 5px;
            font-size: 13px;
        }
    }
    .menu-notification{
        background:$green-gradient;
        border-radius: 20px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
    }
    .bottom-menu{
        display: none
    }
    .dd-league-style{
        width: 300px;
        height: 40px;
    }
}

@media only screen and (max-width:768px){
    .header-container{
        padding: 10px;
        background: white;
        box-shadow: 0px 3px 6px #bdbdbd;
        .logo-conrainer{
            width: 40%;
        }
        .logo{
            width: 35%;
        }
        .active-menu-item{
            border-radius: 0;
            padding: unset
        }
        .header-menu{
           display: none;
        }

        .bottom-menu{
            position: fixed;
            display: flex;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $black-color;
            width: 100%;
            height: 50px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            justify-content: space-evenly;
            z-index: 100;
         }
         .dd-league-style{
            width: 200px;
        }
    }
    .overlay{
        position: fixed;
        width: 100%;
        height: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 200;
        cursor: pointer;
    }
    .overlay-none{
        position: unset;
        width: unset;
        height: unset;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: unset;
        cursor: pointer;
    }
}