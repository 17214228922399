@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.calendar-container{
    display: flex;
    .calendar-for-pc{
        display: flex;
        width: 100%
    }
    .calendar-for-mobile{
        display: none
    }
    .calender-section{
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .matches-head{
        @include font-bold;
        font-size: 30px;
        margin-left: 10px
    }
    .matches-subhead{
        margin-left: 10px
    }

    .matches-container{
        background-color: white;
        width: 90%;
        padding: 10px;
        box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
        border-radius: 10px;
        .matches-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .align-items-center{
            align-items: center;
        }
        .align-items-start{
            align-content: flex-start;
        }
        .matches-content{
            @include font-regular;
            height: 70vh;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 5px;
            .no-result-found{
                text-align: center;
                width: 100%;
            }
        }
        
    }

    .match-card{
        width: 46%;
        border-radius: 10px;
        box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
        margin-top: 10px;
        .match-date{
            text-align: center;
        }
        .prediction-message-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
            .not-prediction-message{
                background: $red-gradient;
                padding: 2px 15px;
                font-size: 13px;
                border-radius: 10px;
                color: white
            }
            .prediction-message{
                background: #eaeaea;
                padding: 2px 15px;
                font-size: 13px;
                border-radius: 10px;
            }

        }
        .teamFlag{
            width: 40px;
            height: 30px;
            border-radius: 6px;
            cursor: pointer;
        }
        .match-details{
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
            align-items: center;
        }
        .non-predicted{
            height: 44px;
            width: 44px;
            border-radius: 30px;
            background: $green-gradient;
            justify-content: center;
            align-items: center;
            display: flex;
            
            .background{
                background:white;
                width: 36px;
                height: 36px;
                border-radius: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .star-icon{
                width: 20px;
                height: 20px;
                // padding-bottom: 2px;
            }
        }
        .predicted{
            .background{
                background:$green-gradient;
                height: 44px;
                width: 44px;
                border-radius: 30px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .star-icon{
                width: 20px;
                height: 20px;
                // padding-bottom: 2px;
            }
            
        }
        .previous-match-goals{

            width: 34px;
            height: 34px;
            border-radius: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            padding-bottom: 2px;
            color: white;
        }
        .divider{
            height: 30px;
            border: 0.5px solid #f2f2f2;
        }
        .win{
            background: $green-gradient;
        }
        .lose{
            background: $black-color
        }
        .team-name{
            font-size: 11px
        }
        .predicted-banner-container{
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: white;
            height: 48px;
            .predicted-banner-content{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 80%;
                padding:0 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .win-color{
                background: $green-gradient;
            }
            .lose-color{
                background: $red-gradient;
            }
            .no-predicted-banner{
                background: $green-gradient;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 80%;
                padding:5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .prediction-points{
                font-size: 20px;
                margin-right: 10px;
            }
            .title{
                font-size: 12px
            }
        }
    }
    .upcoming-results{
        background-color: white;
    }
   
}
.calendar-modal-container{
    display: flex;
    height: 100%;
    
    .section{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 50%;
        padding: 0;
    }

    .section-one{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 40%;
        padding: 0;
    }
    .section-two{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 60%;
        padding: 0;
    }
    &.player-background{
        background-image: url('./../../assets/images/login-page-banner1.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .prediction-instructions-for-pc{
        margin: 10px;
        background: $gray-color-2;
        border-radius: 10px;
        padding: 10px;
        .instructions-for-pc{
            width: 34%;
            padding: 10px
        }
        .match-card{
            width: 60%;
            background: white
        }
    }
    .modal-card{
        box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
        margin: 20px;
        background: white;
        border-radius: 10px;
        padding-top: 10px
    }
    .modal-card-content{
        display: flex;
        align-items: center;
        line-height: 1.5;
    }
    .winner-text{
        font-size: 40px;
        font-weight: bold;
    }
    .text-container{
        margin-left: 10px
    }
    .logo-image{
        width: 40%
    }
    .logo-title{
        margin-left: 10px
    }
    .success-image{
        width: 130px;
    }
    .header-section{
        display: flex;
        align-items: flex-end;
        font-size: 20px;
        justify-content: center
    }
    .cancel{
        padding: 10px;
        position: absolute;
        right: 2%;
        width: 26px;
        cursor: pointer;
    }
    .button-section{
        text-align: -webkit-right;
        // text-align: right;
        width: 100%;
    }
    .prediction-instruction{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;    
        .match-card{
            width: 70%;
        }
        .pred-divider{
            width: 60%;
            border: 0.9px solid #d8d8d8;
            margin: 10px;
        }
    }
    .instructions{
        width: 70%;
        align-items: center;
        padding: 10px;
        text-align: center;
        font-size: 14px
    }
   
    .modal-head{
        display: flex;
        justify-content: space-between
    }
    .modal-title{
        @include font-bold;
        font-size: 40px
    }
    .button-style{
        font-size: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 35%;
        height: 60px;
        .continue-icon{
            width: 15px;
            margin-left: 10px;
        }
    }

}
.modal-style{
    background: white;
    // margin: 2%;
}
.previous-match-card-padding{
    padding: 10px 10px 0;
}
.upcoming-match-card-padding{
    padding: 20px 10px;
}

@media only screen and (max-width:768px){
    .calendar-container{
        background-image: url('./../../assets/images/mobile-background.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: local;
        .calendar-for-pc{
            display: none
        }
        .calendar-for-mobile{
            display: inline;
            width: 100%;
        }
        .matches-head{
            font-size: 1.6rem;
            color: white;
            margin-left: unset;
            // margin-top: 10px;
            display: block;
        }
        .matches-subhead{
            color: white;
            margin-left: unset;
        }
        .matches-container{
            background-color: unset;
            box-shadow:unset;
            width: 100%;
            .match-date-picker{
                margin-top: 10px;
                .date-picker{
                    right: 10px;
                }
                .form-datepicker{
                    font-size: 12px;
                    width: 120px;
                    padding: 15px 8px
                }
            }
            .matches-header{
                align-items: flex-start;
            }
            .matches-content{
                height: 67vh;
                margin-top: 10px;
                -webkit-overflow-scrolling:touch;
            }
        }
    .calender-section{
        width: 100%;
    }
    .match-card{
        width: 100%;
        background-color: white;
    }
}
.calendar-modal-container{
    flex-direction: column;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
    border-radius: 10px;
    height: 85vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .section{
        width: 100%;
        padding: unset;
    }
    .button-style{
        width: 60%;
        height: 50px;
    }
    .button-section{
        text-align: -webkit-center;
        padding-bottom: 10px
    }
    .prediction-instruction{
        .match-card{
            width: 90%;
            padding: 10px;
        }
    } 
    .cancel{
        right: 10px;        
    }
    .calendar-container{
        background-image: none;
    }
    .success-image{
        width: 90px;
        height: 90px;
        margin-top: 10px;
    }
    .modal-title{
        font-size: 25px;
        margin-right: 45px;
    }
}

.modal-style{
    padding: 0;
    background: white;
    margin: 10% 2% 2%;
}
}