
@import url("https://use.typekit.net/bkt2pfb.css");
@import "node_modules/react-toastify/scss/main.scss";
@import "variables";
@import "mixins";



@import "reactDayPicker";
body{
    margin: 0;
    font-family: sofia-pro, sans-serif;
    height: 100vh;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

a{
    text-decoration: none
}
.only-for-mobile{
    display: none;
    @media only screen and (max-width:768px){
        display:inline-block
    }

}
::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $green-gradient; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $green-gradient; 
  }
.only-for-pc{
    display:inline-block;
    @media only screen and (max-width:768px){
        display:none !important
    }

}
@media only screen and (max-width:768px){
    ::-webkit-scrollbar {
        width: 6px;
      }
}