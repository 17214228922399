.video-player-page{
    padding: 0 1rem;
    box-sizing: border-box;
    padding-bottom: 1rem;
    @media screen and (max-width: 768px) {
        padding-bottom: 3.5rem;
    }
    .video-player{
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }    
    .video-title, .related-video-title{
        margin: 1rem 0;
        font-size: 20px;
        font-weight: 700;
    }
    .video-container{
        width: 100%;
        box-sizing: border-box;
        .video-card{
            position: relative;
            width: auto;
            height: 180px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                height: 168px;
            }
            cursor: pointer;
            .video-card-preview img {
                position: absolute;
                width: 100%;
                height: 80%;
                object-fit: cover;
            }
            .video-card-title {
                position: absolute;
                bottom: 10px;
                width: 100% !important;
                line-height: 1em;
                color: #000;
                font-weight: 600;
                padding-right: 5px;
                line-height: 1em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}