@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.login-container{
    height: 100vh;
    overflow: hidden;
    display: flex;
    .login-actions{
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 30px;
        flex-direction: column;
        @media only screen and (max-width:768px){
            margin: 0;
        }
    }
    .login-welcome-page{
       align-items: center;
       display: flex;
       justify-content: center;
       flex-direction: column;
       @media only screen and (max-width:768px){
            background: url('./../../assets/images/player-nb.png');
            background-repeat: no-repeat;
            background-size: 550px;
            height: 100vh;
            width: 100%;
            background-position-x: -150px;
       }
    }
    .login-background-image{
        height: 100%;
        img{
            height: 100%;
            // transform: scaleX(-1);
        }
        @media only screen and (max-width:768px){
            display: none
            
        }
    }
    .login-subtitle{
        @include font-regular;
        font-size: 28px;
        margin-top: 20px;
    }
    .login-button{
        margin-top: 100px
    }
    .login-icon{
        width: 30px;
        height: 30px;
        margin-left: 10px;
        vertical-align: sub;
    }
    .welcom-heading{
        @include font-bold;
        color:$black-color;
        font-size: 45px;
    }
    .welcome-downarrow{
        width: 25px;
        margin-left: 60px;
    }
    .welcome-subheading{
        @include font-regular;
        display: flex;
        color: $black-color;
        font-size: 20px;
        margin-top: 5px;
    }
    .login-form{
        margin-top: 60px;
        .flag_img{
            position: relative;
            width: 11%;
            .flag_url{
                position: absolute;
                width: 30px;
                left: 15px;
                top: 33px;
                height: 20px;
            }
        }
    }
    .loader-size{
        width: 150px !important;
        height: 60px;
    }
    .league-dd-wrapper{
        width: 370px;
        height: 70px;
    }
    .wrapper-image{
        display: none;
    }
}
.login-inputbox{
    margin-top: 40px;
    z-index: 1000;
    position: relative;
}

.rtl{
    direction: rtl;
}

@media only screen and (max-width:768px){
    .login-container{
        flex-direction: column;
        .welcome-logo{
            width: 60%;
            margin-top: 40%;
            margin-left: 20%;
        }
        .login-actions{
            align-items: center;
            height: 100%;
            background-color: #fafafa;
        }
        .login-subtitle{
            display: none
        }
        .login-button{
            margin-top: 10px;
            margin-left: 20%;
        }
        .login-icon {
            width: 20px;
            height: 20px;
        }
        .welcom-heading{
            font-size: 2rem;
        }
        .welcome-subheading{
            font-size: 1rem
        }
        .welcome-downarrow{
            margin-left: 10px;
        }
        .login-form{
            margin-top: 40px;
            .flag_img{
                .flag_url{
                    left: 10px;
                    top: 21px;
                }
            }
        }
        .league-dd-wrapper{
            width: 250px;
        }
        .login-form-league-container{
            margin-bottom: 50px
        }
        .wrapper-image{
            display: inline;
            width: 100%;
            position: fixed;
            bottom: -30px;
            left: -30px;
            .image{
                width: 150px;
                height: 150px;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(40deg);
            }
        }
    }
}