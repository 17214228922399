@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';


.button{
    width: 370px;
    height: 80px;
    border-radius: 10px;
    font-size: 23px;
    border: none;
    @include font-bold;
    cursor: pointer;
}
.is-primary-button{
    color: white;
    background: $light-green;
    background: linear-gradient(180deg, rgba(0,244,188,1) 35%, rgba(50,150,150,1) 100%);
}

.is-secondary-button{
    color: $light-green;
    background: $black-color;
}
.simple-input{
    @include font-regular;
    color: $black-color;
    border-radius: 8px;
    width: 370px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    input{
        border: none;
        height: 40px;
        width: 60%;
        font-size: 24px;
        @include font-regular;
        color: $black-color;
        &:focus{
            outline: none
        }
        &::placeholder{
            font-size: 22px;
        }
    }
    .placeholder-conetnt{
        font-size: 23px;
        .placeholder-text{
            margin-left: 10px
        }
    }
}
.border-black{
    border: 1px solid $black-color;
}
.border-color{
    border:1px solid $light-green
}

.simple-dropdown{
   
        z-index: 10000000;
        position: relative;
    
    .css-x0elec-placeholder{
        margin-left: unset !important;
        margin-right: unset !important;
    }
    .css-dvua67-singleValue{
        font-size: 24px;
        padding-left: 20%;
    }
    .css-1g6gooi {
    }
  
    .css-1pcexqc-container{
        width: 370px !important;
        .css-bg1rzq-control{
            min-height: 80px !important;
            border-radius: 10px;
            border: 1px solid $black-color !important; 
            // &:focus{
            //     border-radius: 10px !important;;
            //     border: none !important; 
            //     box-shadow: none!important;
            // }
            
        }
        // .css-1szy77t-control{
        //     border-color: none ;
        //     border-radius: 10px;
        //     box-shadow: none!important;
        //     &:hover{
        //         border-color: none !important;
        //     }
        // }
        .css-1hwfws3{
            min-height: 80px !important;;
        }
    }
}
.form-datepicker{
    @include font-bold;
    width: 230px;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
    .downArrow{
        width: 15px;
        height: 15px;
    }

}

.error-message{
    color: red
}
.custom-dropdown-wrapper{
    width:220px;
    cursor: pointer;
    .dd-list{
        position: absolute;
        background: white;
        width: inherit;
        border-radius: 10px;
        margin-top: 2px;
        box-shadow: 0px 1px 6px rgba(0,0,0,0.12);
        overflow-y: auto;
        max-height: 200px;
        z-index: 300;
        cursor: pointer;
        z-index: 100000;
    }
    .dd-list-item{
        padding: 10px;
        display: flex;
    }
    .selected-item{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .dd-header-title{
        display: flex;
        align-items: center;
    }
    .dd-list-item-img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .dd-header{
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        // padding: 10px;

    }
    .border{
        border: 1px solid;
        border-radius: 10px;
    }
    .dd-down-arrow-icon{
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
    .noBorder{
        border: none !important;
        justify-content:flex-end !important;
    }
}

@media only screen and (max-width:768px){
    .button{
        width: 250px;
        height: 60px;
        font-size: 20px;
    }
    .simple-input{   
        width: 250px;
        height: 60px;
        background-color: white;
        .placeholder-conetnt{
            font-size: 18px;
        }
        input{
            font-size: 18px;
        }
    }
    .simple-dropdown{
        .css-x0elec-placeholder{
            margin-left: unset !important;
            margin-right: unset !important;
            font-size: 15px !important
        }
        .css-1pcexqc-container{
            width: 250px !important;
            .css-bg1rzq-control{
                min-height: 60px !important;
            }
            .css-1hwfws3{
                min-height: 60px !important;
            }
        }
    }
    .form-datepicker {
        width: 130px;
        padding: 15px;
        background-color: white;
    }
    .custom-dropdown-wrapper{
        .dd-list-item{
          font-size: 12px
        }
    }

}