.profile-subscribe-info-section {
    border-bottom: 1px solid #329696;
    .profile-subscribe-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2% 0%;
        h1{
            color: #329696;
            font-size: 1em;
            font-weight: 600;
            margin: 0;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
    .subscribe-ans{
        font-size: 0.9em;
        margin-bottom: 3%;
    }
}