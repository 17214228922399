@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

$min_width: 320px;
$max_width: 1200px;
$min_font: 20px;
$max_font: 30px;
.spash-screen{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;

    .spash-screen-img{
        width: 90%;
        max-width: 500px;
        max-height: 160px
    }
    .spash-screen-title{
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
}