
//Color Names
//Note: Don't use the color directly on component, define a component color name that routes the colors
$light-green:#00F4BC;
$dark-green:#329696;
$green-gradient: linear-gradient(180deg, rgba(0,244,188,1) 35%, rgba(50,150,150,1) 100%);
$red-gradient: linear-gradient(180deg, rgba(241,196,196,1) 19%, rgba(223,40,29,1) 100%);;

$light-red-color: rgb(241,196,196);
$black-color:#1D1D1B;
$gray-color:#EDEDED;
$gray-color-2:#F8F8F8;
