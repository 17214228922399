@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';


.live-score-container{
    display: flex;
}
.live-score-card-container{
        display:flex;
        flex-direction: column;
        width:90%;
        margin-bottom:40px;
        max-width:500px;
        
    .top-bar {
        display:flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .title::before {
        content:"";
        width:35px;
        height:35px;
        background:url('./../../assets/images/icons/fire-1.svg') no-repeat;
        background-size:contain;
        display:inline-block;
    }
    .title {
        font-weight: 700;
        font-size:2em;
    }
    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height:30px;
        padding:0px 15px;
        box-shadow: 0px -4px 5px rgba(0,0,0,0.05);
        border-radius:10px 10px 0px 0px;
        background:white;
        align-self: flex-end;
        margin-left: 7px;
    
    }
    .box {
        display:flex;
        flex-direction: column;
        width:100%;
        max-width:500px;
        background:white;
        box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
        border-radius:10px 0px 10px 10px;
        padding-bottom: 30px;
    }
    .scores {
        display:flex;
        justify-content: space-around;
        margin:30px 0px 15px 0px;
    }
    .homeTeamFlag{
        width:50px;
        height:30px;
        border-radius:5px;
        margin-right:10px;
    }
    .awayTeamFlag{
        width:50px;
        height:30px;
        border-radius:5px;
        margin-left:10px;
    }
    .gha::after {
        display: inline-block;
        content:"";
        background:url('./../../assets/images/zim.png');
        width:50px;
        height:30px;
        border-radius:5px;
        background-size:contain;
        background-position: center;
        margin-left:10px;
    }
    .win {
        background: linear-gradient(#00F4BC, #329696);
        color:white;
    }
    .lose {
    
        background: #1D1D1B;
        color:white;
    }
    
    .divider {
        height:40px;
        background:#f2f2f2;
        width:1px;
    }
    
    .flag {
        display: flex;
        align-items: center;
    }
    
    .score {
        width:50px;
        height:50px;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:25px;
        font-weight:700;
        border-radius:100px;
    }
    
    .goal {
        color:#1D1D1B;
        margin-left:15px;
        font-size:12px;
    }
    
}
.score-container{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .team-statistics-head{
        display: none
    }
    .cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%
    }
    .matches-result-button{
        color: white;
        text-align: center;
        border: 2px solid white;
        border-radius: 10px;
        padding: 20px;
        font-size: 30px;
        position: absolute;
        bottom: 80px;
        cursor: pointer;
        &:hover{
            transition: 0.4s ease-in-out;
             box-shadow:0 5px 5px rgba(0,0,0,0.18);
        }
        .matches-result-button-icon{
            width: 30px;
            height: 30px;
            margin-left: 20px
        }
    }
    .play-now-card{
        display: flex;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #bdbdbd;
        padding: 20px 20px 20px 0;
        padding: 10px 10px 10px 0;
        max-width: 500px;
        width: 90%;
        justify-content: space-between;
        .success-image{
            width: 32%
        }
        .play-now-card-content{
            @include font-regular;
            color: $black-color;
            display: flex;
            flex-direction: column;
        }
        .play-now-card-text1{
            font-size: 25px;
        }
        .play-now-card-text2{
            @include font-bold;
            font-size: 50px;
            color: $light-green;
        }
        .play-now-card-text3{
            font-size: 18px;
        }
        .play-now-button{
            width: 280px;
            margin-top: 10px;
            cursor: pointer;
            .play-now-button-icon{
                width: 15px;
                margin-left: 10px;
                vertical-align: middle;
            }
        }
        .play-now-card-cancel-button{
            position: relative;
            left: 280px;
            top: -110px;
            width: 30px;
        }
    }
    
}
.ranking-container{
    width: 60%;
    margin: 0 40px;
    .ranking-table{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: auto;
        height: 80vh;
    }
    .team-flag{
        width: 28px;
        height: 24px;
        padding: 0 10px;
    }
    .ranking-heading{
        @include font-bold;
        font-size: 2em;
        margin-left: 20px;
        
    }
    .ranking-heading::before{
         content:"";
        width:35px;
        height:35px;
        background:url('./../../assets/images/icons/medal-2.svg') no-repeat;
        background-size:contain;
        display:inline-block;
        margin:0 10px
    }
    .ranking-headingicon{
        width: 35px;
        height: 35px;
    }
    .team-content{
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
        cursor: pointer;
    }
    .team-content-withoutgroup{
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        width: 48%;
        // height: 40px;
        display: flex;
        align-items: center;
        background: white;
        margin: 2px;
    }
    .group-name{
        text-align: left
    }
    .wt-group-name{
        text-align: left;
        width: 55%;
    }
    .team-name{
        width: 55%;
        font-size: 12px;
        font-weight: normal;
        border-radius:10px;
        background: white;
    }
    .team-srno{
        padding: 0 10px;
    }
    .group-standing{
        width: 48%
    }
    .standings{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        .second-row{
            width: 48%;
            display: flex;
        }
    }
    .standing-heads-second-row{
        width: 48%;
        display: flex;
    }
    .standing-head{
        width: 48%;
        display: flex;
    }
    .group-content{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: normal;
        height: 40px;
        align-items: center;
        padding: 0 20px 0 0;
    }
    .wt-group-content{
        width: 40%
    }
    .group-head{

    }
}

@media only screen and (max-width:768px){
    .live-score-container{
        flex-direction: column;
        height: 84vh;
        overflow-y: auto;
        -webkit-overflow-scrolling:touch;
      
    }
    .live-score-card-container{
        // margin-top: 20px;
        margin-bottom: unset;
        .title {
            font-weight: 700;
            font-size:1.5em;
            color:white
        }
        .title::before {
            background:url('./../../assets/images/icons/fire-white.svg') no-repeat;

        }
    }
    .score-container{
        width: 100%;
        display: unset;
        &.score-container-bg{
            background-image: url('./../../assets/images/mobile-background.png');
            background-repeat: no-repeat;
            background-size: 100%;
            padding-top: 20px;
        }
        .score-container-without-bg{
            background-image: unset;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .team-statistics-head{
            display:inline-block;
            font-size: 25px;
            padding: 10px;
            color: white;
        }
        .cards-container{
            flex-direction: column-reverse;
        }
        .play-now-card{
            .play-now-card-text1{
                font-size: 18px;
            }
            .play-now-card-text2{
                font-size: 35px;
            }
            .play-now-card-text3{
                font-size: 14px;
            }
            .play-now-button{
                width: 220px;
                .play-now-button-icon{
                    width: 10px;
                }
            }
        }
    }

    .ranking-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;    
        margin: 20px 0 0 0;
        .standing-heads-second-row{
            display: none
        }
        .standing-head{
            width: 100%;
        }
        .team-content-withoutgroup{
            width: 100%
        }
        .ranking-heading-container{
            text-align: left;
            display: flex;
            align-self: flex-start;
            margin-left: 5%;
        }
        .ranking-heading::before{
            width: 25px;
            height: 25px;
        }
        .ranking-heading{
            font-size: 1.5em;
            margin-left: unset;
            
        }
        .ranking-table{
            overflow-y: unset;
            height: unset;
            justify-content: center;
            padding: 10px;
        }
        .group-standing{
            width:100%
        }
           
    }
    .matches-result-button{
        display: none
    }
}

    .group-content td:nth-child(4) {
        color: #00F4BC;
        font-weight: bold;
    }