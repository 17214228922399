.leagues{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .leagues-container{ display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    width: 100%;
    }
    .league-details{
        background: #eaecec;
        padding: 10px;
        border-radius: 10px;
    }
    .league-logo{
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-width:768px){
    .leagues{

        .league-logo{
            width: 130px;
            height: 130px;
        }
    .leagues-container{
        padding-bottom: 150px;
       overflow-y: auto
    }
    .league-details{
       margin: 10px
    }
}
}