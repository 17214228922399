@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';


.profile-container{
    display: flex;
.profile-container-player{
    width: 40%;
}
.reset-button{
    margin-top: 20px;
    font-size: 23px;
    background:$green-gradient;
    width: 100px;
    text-align: center;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}
.last-predictions-content{
    // height: 32vh;
    // overflow-y: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
}
.select-language{
    height: 30px;
    width: 80px;
    outline: none;  

    
}
.profile-container-score{
    width: 60%;
    display: flex;
    justify-content: center;
    .edit-profile-form{
        width: 80%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #bdbdbd;
        background: white;
        padding: 20px;
        height: 72vh;
        .loader-size{
            width: 150px !important;
            height: 60px;
        }
        .save-button-icon{
            width: 15px;
            height: 25px;
            margin-left: 10px;
            vertical-align: sub;
        }
        .edit-profile-title{
            font-size: 25px;
            color: $light-green
        }
        .form-field{
            margin-top: 20px
        }
        .save-button{
            margin-top: 20px
        }
    }
    .container-score{
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.1);
        width: 90%;
        height: 70vh;
        background: #fff;
        padding: 20px;
        .subscribe_instruction_section{
            margin-top: 3%;
        }
    }
    .unset-height{
        height: unset !important
    }
    .heading-style{
        @include font-bold;
        font-size: 36px;
        margin: 0px;
        display: flex;
        justify-content: space-between
        
    }
    .team-flag{
        height: 40px;
        width: 40px;
        border-radius: 10px;
        margin-right: 5px;
    }

    .user-name{
        margin:0px;
        font-size:17px
    }
    .person-score-rank{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .person{
            align-items: center;
            border-radius: 8px;
            box-shadow: 0px 3px 6px #bdbdbd;
            width: 45%;
            display: flex;
            justify-content: space-between;
            height: 80px;
            padding: 10px;
            .edit-button-icon{
                height: 15px;
                margin-left: 10px;
            }
            .user-account-info{
                display: flex;
                justify-content:center;
                align-items: center;
                margin-left:4%;
            }
            .edit-button{
                height:40px;
                width:100px;
                font-size: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right:4%;
            }
            .edit-button-text{
                font-size:17px;
                text-align:left;
                margin:5px 0 5px 0;
            }
        }
        .score-rank{
            //margin: 0px 34px 0px 34px;
            //border-radius: 8px;
            //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            //margin: 0px;
            //padding: 0px 34px 0px 34px;
            width: 45%;
            display: flex;
            height: 100px;
            .score-rank-design{
                //margin: 0px 0px 0px 34px;
                //float: right;
                border-radius: 8px;
                box-shadow: 0px 3px 6px #bdbdbd;
                width: 100%;
                padding: 10px;
                background-image: linear-gradient(270deg, #00F4BC , #329696);
                color: white;
                justify-content: space-between;
                display: flex;
            }
            .score-rank-title{
                font-size: 16px;
                line-height:26px;
            }
            .rank-points{
                font-size: 30px;
                margin-right: 10px;            
            }
            .score-rank-trophy{
                width: 50px
            }
            .score-rank-star{
                width: 25px;
            }
            .score-rank-content{
                display: flex;
                justify-content: space-between
            }
        }
    }
}

.heading-style-predictions{
    @include font-bold;
    font-size: 24px;
    font-family: 'Sofia Pro';
    padding: 80px 0 0px 31px;
    margin-bottom: 0px;
    //margin-top: 50px;
}

.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
    .box{
        width: 40%;
        height: 80px;
        //background: yellow;
        //display: flex;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #bdbdbd;
        //box-sizing: border-box;
        //font-size: 30px;
    }
}
.last-predictions{
    margin-top: 20px;
    .header{
        @include font-bold;
        font-size: 25px;
    }
    .last-predictions-blank{
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 10%;
        span{
            text-align: center
        }
    }
    .gh-style{
        margin-top: 6%;
    }
}
.unsubscribe-section{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 95%;
    align-items: center;
    .unsubscribe-content{
        margin-right: 20px
    }
    .unsubscribe-button{
        width: 150px;
        height: 35px;
        border-radius: 8px;
        font-size: 14px;
        vertical-align: middle;
        border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer !important;
    }
}

}

.text-style{
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: 0px;
}
.download-apk{
    font-size: 16px;
    border: 1px solid $dark-green;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
@media only screen and (max-width:768px){

    
    .profile-container{
        background-image: url('./../../assets/images/mobile-background.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: local;
        .last-predictions{
            margin-top: 10px;
            .header{
                font-size: 18px;
            }
        }
        .last-predictions-content{
            height: unset;
            background-image:none;
        }
        .reset-button{
            margin-top: 10px;
        }
        .select-language{
            color:white;
            font-size: 15px;
            // margin-left: 90px;
            border-color: white;
            border-width: 2px;
            background: transparent;

            option{
                background: white;
                color:black
            }
        }
    .profile-container-player{
        display: none
    }
    .profile-container-score{
        width: 100%;
        height: 85vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        .edit-profile-form{
            margin-top: 20px
        }
        .heading-style{
            color: white;
            font-size: 1.6em;
            line-height: 1em;
        }
        .person-score-rank {
            flex-direction: column;
            margin-top: 10px;
            .person{
                width: 94%;
                background: white;
                height: 60px;
                .edit-button{
                    height:35px;
                }
            }
            .edit-button{
                height:35px;
            }
            .score-rank{
                width: 100%;
                height: 70px;
                margin-top: 10px;
                .rank-points{
                    font-size: 25px;
                }
            }
        }

        .score-rank-title {
            line-height:18px
        }
        
        .container-score{
            box-shadow: unset;
            // width: 100%;
            // padding: 10px;
            height: 65vh;
            background:unset;
        }
    }
    .unsubscribe-section{
        padding: 10px;
    }
}

.last-predictions-blank  {
    background-image:none;
}
.download-apk{
    font-size: 10px;
    border: 1px solid #f9fbfb;
    height: 30px;
}

}