@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.leaderboard{
    height: 100%;
    .empty-leaderboard{
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    .trophy-icon{
        width: 80px
    }
    .leaderboard-head{
        margin-left: 30px
    }
    .leaderboard-title{
        @include font-bold;
        font-size: 40px;
    }
    .leaderboard-data{
        padding: 25px 15px;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 10px 10px 0px 0px;
        background: white;
        margin: 20px;
        height: 60vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-x: auto;
        .user-points-container{
            display: flex;
            flex-direction: column;
            width: 30%;
            margin: 0 30px;
        }
        .user-points{
            width: 100%;
            display: flex;
            margin: 15px 0;
            justify-content: space-between;
            .name-container{
                display: flex;
                justify-content: center;
                align-items: center
            }
            .rank{
                border-radius: 22px;
                background: $green-gradient;
                width: 30px;
                height: 30px;
                text-align: center;
                color: white
            }
            .name{
                margin-left: 10px
            }
            .points-container{
                display: flex;
                padding: 0 10px;
                width: 20%;
                height: 35px;
                background: $green-gradient;
                border-radius: 10px;
                justify-content: space-between;
                align-items: center;
            }
            .points{
               font-size: 18px;
               color: white;
            }
            
        }
        .divider{
            width: 100%;
            border: 1px solid $gray-color;
        }
    }
}
@media only screen and (max-width:768px){
    .leaderboard{
        width: 100%;
        background-image: url('./../../assets/images/mobile-background.png');
        background-repeat: no-repeat;
        background-size: contain;
        .trophy-icon{
            width: 30px;
            margin-right: 10px
        }
        .leaderboard-head{
            margin-left: unset;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 10%;
        }
        .leaderboard-title{
            @include font-bold;
            font-size: 30px;
            color: white;
            
        }
        .leaderboard-title::after{
            background-image: url('./../../assets/images/icons/trophy-2.svg') no-repeat;
        }
        .leaderboard-data{
            margin: 0 10px;
            height: 68vh;
            flex-direction: row;
            overflow-x: unset;
            overflow-y: auto;
            .user-points-container{
                width: 100%;
                margin: unset
            }
        }
    }
   
}